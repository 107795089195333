import { createContext, useState, useEffect } from 'react';
import memoize from 'memoize-one';

import fetcher from 'src/utils/fetcher';

type Highlight = {
    query: string;
    strings: string[];
};

const HIGHLIGHTER_URL = '/highlight';

declare global {
    interface FetcherPostApi {
        [HIGHLIGHTER_URL]: {
            queryParams: void;
            body: Highlight;
            response: Highlight;
        };
    }
}

interface AsyncHighlighterGetter {
    (string: string, callback: (string: string) => void): void;
}

export const getAsyncHighlighter = memoize((query: string) => {
    let timeout: ReturnType<typeof setTimeout> | null;
    let requestedStringsCallbacks: Record<string, ((string: string) => void)[]> = {};
    const highlightedStringsCache: Record<string, string> = {};

    async function fetch() {
        timeout = null;
        const pendingStrings = Object.keys(requestedStringsCallbacks);
        if (pendingStrings.length) {
            const pendingStringsCallbacks = requestedStringsCallbacks;
            requestedStringsCallbacks = {};

            try {
                const response = await fetcher.post(HIGHLIGHTER_URL, { query, strings: pendingStrings });
                pendingStrings.forEach((string, index) => {
                    highlightedStringsCache[string] = response.data.strings[index];
                    pendingStringsCallbacks[string].forEach((callback) => callback(highlightedStringsCache[string]));
                });
            } catch (e) {} // eslint-disable-line no-empty
        }
    }

    const getAsyncHighlighted: AsyncHighlighterGetter = (string, callback) => {
        if (highlightedStringsCache.hasOwnProperty(string)) {
            // У функции асинхронный контракт
            setTimeout(() => {
                callback(highlightedStringsCache[string]);
            }, 0);
        } else {
            if (!requestedStringsCallbacks.hasOwnProperty(string)) {
                requestedStringsCallbacks[string] = [];
            }
            requestedStringsCallbacks[string].push(callback);
            timeout = timeout || setTimeout(() => void fetch(), 0);
        }
    };

    function useHighlighted(string: string) {
        const [highlightedString, setHighlightedString] = useState(string);
        useEffect(() => {
            if (!process.env.SSR && query.trim() && string.trim()) {
                getAsyncHighlighted(string, setHighlightedString);
            }
        }, [string]);
        return highlightedString;
    }

    return { useHighlighted };
});

const AsyncHighlighterStub = {
    useHighlighted: (string: string) => string,
};

export const AsyncHighlighterContext = createContext(AsyncHighlighterStub);
